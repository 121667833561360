.button-container {
  display: inline-block;
  overflow: hidden;
  width: 100px;
  height: 50px;
  border: 1px solid black;
}

.button {
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  font-family: "Teko", sans-serif;
  font-size: 1.5rem;
  border: none;
}

.button:hover {
  cursor: pointer;
  transform: translate(2px, 2px);
  box-shadow: -1px -1px 6px #c3c3c3;
}

.button:active {
  transform: translate(8px, 8px);
  box-shadow: -1px -1px 8px #3a3a3a;
}
