.home-header {
  background-image: url("/images/header_background_2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.home-container {
  height: 60vh;
}

.home-header_titles {
  width: 100%;
  padding: 5px;
  font-size: 5rem;
  color: white;
  font-family: "Teko", sans-serif;
}

.home-header_titles:first-child {
  text-align: center;
  font-size: 6rem;
}

.home-header_titles:nth-child(2) {
  text-align: left;
  color: rgb(255, 255, 90);
  font-size: 4rem;
}

.home-header_titles:last-child {
  text-align: right;
  color: rgb(249, 106, 249);
}

.home-main_section {
  margin: 50px 0px;
}

.home-section_title {
  font-family: "Teko", sans-serif;
  font-size: 5rem;
  margin-bottom: 50px;
}

.home-logos {
  display: inline-block;
  width: 200px;
  height: 150px;
  padding: 20px;
}

.home-logo_image {
  float: left;
  width: 125px;
  transition: 0.2s;
}

.home-logo_image:hover {
  /*filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg);*/
  filter: blur(1px);
  transition: 0.2s;
}

@media only screen and (max-width: 600px) {
  $titlesAlign: center;
  $fontSize: 3rem;

  .home-header_titles:first-child {
    text-align: $titlesAlign;
    font-size: $fontSize;
  }

  .home-header_titles:nth-child(2) {
    text-align: $titlesAlign;
    font-size: $fontSize;
  }

  .home-header_titles:last-child {
    text-align: $titlesAlign;
    font-size: $fontSize;
  }

  .home-section_title {
    font-size: 3rem;
  }

  .home-logos {
    width: 40%;
  }
}
