/* Define standard variables and values for website */
$bgcolor: rgb(240, 240, 240);
$textcolor: darkblue;
$fontsize: 1.5rem;

.nav {
  background-color: $bgcolor;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  overflow: visible;
  font-family: "Teko", sans-serif;
  font-size: $fontsize;
}

.nav-logo {
  float: left;
  width: 125px;
  transition: 0.2s;
}

.nav-logo:hover {
  /*filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg);*/
  filter: blur(1px) sepia(100%) saturate(300%);
  transition: 0.2s;
}

.nav-logo:active {
  filter: blur(2px) sepia(100%) saturate(150%);
}

/* Use the variables */
#nav-list {
  display: flex;
  justify-content: flex-end;
  float: right;
  width: 50%;
  list-style: none;
}

.nav-list_item {
  display: inline;
  transition: 0.2s;
}

.nav-list_item:hover {
  transition: 0.5s;
  background-color: $bgcolor;
  transform: translate(2px, 0px);
  box-shadow: -1px 0px 5px #888888;
}

.nav-list_navlink:active {
  transition: 0.5s;
  color: grey;
}

.nav-list_navlink.active {
  background-color: $bgcolor;
  transform: translate(1px, 0px);
  box-shadow: -1px 0px 2px #888888;
}

.nav-list_navlink {
  color: black;
  display: block;
  padding: 20px;
  text-decoration: none;
  text-align: center;
}

#nav-burger {
  display: none;
}

@media only screen and (max-width: 600px) {
  #nav-list {
    display: none;
    position: fixed;
    right: 0;
    top: 60px;
    background-color: white;
    box-shadow: 2px 2px 4px grey;
  }

  #nav-burger {
    display: block;
    float: right;
    width: 50px;
    margin: 15px;
  }

  #nav-burger_bar_1 {
    transition: 0.5s;
    border-bottom: 5px solid black;
    margin-bottom: 8px;
  }

  #nav-burger_bar_2 {
    transition: 0.25s;
    border-bottom: 5px solid black;
    margin-bottom: 8px;
  }

  #nav-burger_bar_3 {
    transition: 0.5s;
    border-bottom: 5px solid black;
    margin-bottom: 8px;
  }
}
