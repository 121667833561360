$bgcolor: rgb(240, 240, 240);
$fontSize: 2rem;

.about_me-header {
  background-color: rgb(48, 48, 48);
  padding: 50px;
}

.about_me-left_side {
  display: inline-block;
  width: 50%;
}

.about_me-right_side {
  display: inline-block;
  width: 50%;
  text-align: left;
}

.about_me-profile_image_container {
  display: inline-block;
  width: 300px;
  height: 300px;
  border: 5px solid rgb(255, 255, 255);
  border-radius: 100%;
  overflow: hidden;
}

.about_me-profile_image {
  position: relative;
  height: 100%;
  right: 20px;
}

.about_me-title {
  width: 100%;
}

.about_me-section {
  padding: 50px;
  text-align: left;
}

.about_me-section:nth-child(even) {
  background-color: $bgcolor;
}

.about_me-section_text {
  font-family: "Teko", sans-serif;
  font-size: $fontSize;
}

.about_me-workspace {
  width: 100%;
  border: 5px solid black;
}

@media only screen and (max-width: 600px) {
  $fontSize: 1.25rem;

  .about_me-profile_image_container {
    width: 100px;
    height: 100px;
  }

  .section_title {
    font-size: 2.5rem;
  }

  .about_me-section_text {
    font-size: $fontSize;
  }
}
