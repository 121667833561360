.showcase-container {
  display: inline-block;
  width: 28%;
  border: 1px solid black;
  margin: 10px;
  padding: 15px;
}

.showcase-title {
  display: block;
  font-size: 3rem;
  text-align: left;
  color: black;
  text-decoration: none;
  font-family: "Teko", sans-serif;
}

.showcase-border_line {
  width: 0px;
  border-top: 2px solid black;
  margin-bottom: 10px;
}

.showcase-title:hover .showcase-border_line {
  transition: 1s;
  width: 100%;
  border-top: 2px solid black;
}

.showcase-description {
  margin-bottom: 10px;
  text-align: left;
}

.showcase-image_container {
  overflow: hidden;
  border: 2px solid rgb(0, 0, 0);
  height: 200px;
}

.showcase-image {
  transition: 0.5s;
  width: 100%;
  height: 200px;
}

.showcase-image:hover {
  transition: 2s;
  transform: scale(2);
}

@media only screen and (max-width: 600px) {
  .showcase-container {
    width: 85%;
    padding: 15px;
  }
}
