.portfolio-section {
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid black;
  margin: 50px auto;
  padding-bottom: 50px;
}

.portfolio-section:last-child {
  border: none;
}

.section_title {
  font-family: "Teko", sans-serif;
  font-size: 5rem;
  margin-bottom: 50px;
}

@media only screen and (max-width: 600px) {
  .section_title {
    font-size: 3rem;
  }

  .portfolio-section {
    width: 90%;
  }
}
