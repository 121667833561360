.portfolio_details-panel {
  width: 1000;
  border: 1px solid black;
  text-align: left;
  padding: 50px;
  margin: 0px auto;
}

.portfolio_details-short_description {
  padding-bottom: 20px;
  font-family: "Teko", sans-serif;
  font-size: 2rem;
}

.portfolio_details-player {
  border: 2px solid black;
  margin: 50px auto;
}

.portfolio_details-buttons {
  border-bottom: 1px solid black;
  width: 100%;
  display: block;
  padding-bottom: 30px;
}

.portfolio_details-description_title {
  display: inline-block;
  font-family: "Teko", sans-serif;
  font-size: 2rem;
}

.portfolio_details-button {
  float: right;
}

.portfolio_details-long_description {
  margin: 20px 0px;
  font-size: 1rem;
  white-space: pre-wrap;
}

@media only screen and (max-width: 600px) {
  .portfolio_details-panel {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 85vw;
  }

  .portfolio_details-short_description {
    padding-bottom: 20px;
    font-family: "Teko", sans-serif;
    font-size: 1.5rem;
  }

  .portfolio_details-player {
    margin: 20px auto;
  }

  .portfolio_details-buttons {
    width: 100%;
    display: block;
    padding-bottom: 30px;
  }
}
