.footer {
  height: 350px;
  background: rgb(240, 240, 240);
}

.footer-logo {
  display: block;
  width: 300px;
  margin: 0px auto;
}

.footer-logo:hover {
  transition: 0.5s;
  filter: blur(2px);
}

.footer-email {
  display: block;
  width: 250px;
  margin: 0px auto 30px auto;
  padding: 5px;

  font-size: 20px;

  text-decoration: none;
  border-bottom: 1px solid black;
  color: grey;
  transition: 1s;
}

.footer-email:hover {
  transition: 1s;
  width: 300px;
  border-bottom: 1px solid black;
  color: black;
}

.footer-copyright {
  margin-bottom: 20px;

  font-size: 12px;
}
