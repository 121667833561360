.contact-email {
  display: inline-block;
  font-size: 2rem;
}

.contact-email_box {
  margin-top: 300px;
}

@media only screen and (max-width: 600px) {
  .contact-email {
    font-size: 1rem;
  }

  .contact-email_box {
    margin-top: 250px;
  }
}
